import type { PopoverOrigin } from "@mui/material";
import appConfig from "config/appConfig";

import type { TFunction } from "i18next";
import type { DurationLikeObject } from "luxon";
import { DateTime, Interval } from "luxon";

import { genderList } from "shared/data";
import type { MediaModel, MediaModelFullObject } from "shared/types";
import { AgeGroupEnum, GenderEnum, GenderValEnum } from "shared/types";

import type { DropdownOptionModel } from "shared/uikit/Input/Dropdown";

import { MenuListPositionOpts } from "shared/uikit/types";
import { getDateTime } from "utils/getDateTime";

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const generateFakeId = () => {
	let text = "";
	const possible = "abcdefghijklmnopqrstuvwxyz0123456789";

	for (let i = 0; i < 5; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}

	return text;
};

export const uuidv4 = (): string => {
	// @ts-expect-error: special code
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		// @ts-expect-error: special code
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	);
};

export const makeId = (length = 5) => {
	let result = "";
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
};

export function* generateSequence(start, end) {
	for (let i = start; i <= end; i++) yield i;
}

export function randomInteger(min, max) {
	return Math.floor(Math.random() * (max - min)) + min;
}

const toBase64 = (file: File): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = error => reject(error);
	});

async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
	const res: Response = await fetch(dataUrl);
	const blob: Blob = await res.blob();
	return new File([blob], fileName, { type: "image/png" });
}

const extractContentFromHTML = (s: string): string => {
	const span = document.createElement("span");
	span.innerHTML = s;

	const hasInsertedSmartComponent =
		span.querySelectorAll("div[data-name]").length || span.querySelectorAll("img").length;

	return span.textContent || span.innerText || (hasInsertedSmartComponent ? "...content" : "");
};

const maxNameLength = 60;
const correctName = (name = "", length = maxNameLength) =>
	name
		.replace(/^ {1}/gm, "") // Remove first spaces
		.replace(/[^\s&/\\#,+()$~%.'":;*?<>{}`[\]|!@^\-a-zA-Z0-9]/g, "_") // Replace special characters with _
		.replace(/\s\s+/g, " ") // replace multi whitespaces into one whitespace
		.slice(0, length);

const safelyParseJSON = <T>(json): T => {
	let parsed;

	try {
		parsed = JSON.parse(json);
	} catch (error) {}

	return parsed as T;
};

const filterFiles = (files: FileList, types: string[]): FileList => {
	const dt = new DataTransfer();
	const correctTypes = types.map(x => (x.includes("/*") ? x.replace("/*", "/") : x));
	Array.from(files).forEach(file => {
		if (correctTypes.some(x => file.type.startsWith(x))) {
			dt.items.add(file);
		}
	});
	return dt.files;
};

const convertToNumberFormat = (val: string): string => {
	if (val) {
		let modified = val.replace(/[^0-9.]/g, "").replace(/\.{2,}/g, ".");
		const dotCount = modified.split(".").length - 1;
		if (dotCount > 1) {
			let updated = "";
			modified.split(".").forEach((item, i) => {
				updated += `${item}${!i ? "." : ""}`;
			});
			modified = updated;
		}
		return !modified || isNaN(Number(modified))
			? ""
			: modified.endsWith(".")
				? `${Number(modified).toString()}.`
				: Number(modified).toString();
	}
	return "";
};

// Take an image URL, downscale it to the given width, and return a new image URL.
const downscaleImage = async (
	dataUrl: string,
	newWidth: number,
	imageType: string,
	imageArguments: number
): Promise<string> => {
	const imgType = imageType || "image/jpeg";
	const imgArguments = imageArguments || 0.7;

	// Create a temporary image so that we can compute the height of the downscaled image.
	const image = new Image();
	image.src = dataUrl;

	return new Promise(resolve => {
		image.onload = () => {
			const oldWidth = image.width;
			const oldHeight = image.height;
			const newHeight = Math.floor((oldHeight / oldWidth) * newWidth);

			// Create a temporary canvas to draw the downscaled image on.
			const canvas = document.createElement("canvas");
			canvas.width = newWidth;
			canvas.height = newHeight;

			// Draw the downscaled image on the canvas and return the new data URL.
			const ctx = canvas.getContext("2d");
			ctx!.drawImage(image, 0, 0, newWidth, newHeight);
			resolve(canvas.toDataURL(imgType, imgArguments));
		};
	});
};

export const convertToDecimal = (amount: string | number) => {
	return typeof amount === "string" ? Number(Number(amount).toFixed(2)) : Number(amount.toFixed(2));
};

const numberWithCommas = (x: number | string): string => {
	if (x) {
		const parts = x.toString().split(".");
		if (parts?.length && parts[0]) {
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return parts.join(".");
		}
		return x.toString();
	}
	return "";
};

const formatCount = (n: number, singular: string, multiple?: string): string => {
	if (n === 0) return `No ${multiple || singular + "s"}`;
	if (n === 1) return `${n} ${singular}`;

	if (n < 1e3) return `${n} ${multiple || singular + "s"}`;
	if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + `K ${multiple || singular + "s"}`;
	if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + `M ${multiple || singular + "s"}`;

	return `${n}`;
};

const isMobileSafari = () => {
	const isIOS =
		(/iPad|iPhone|iPod/.test(navigator.platform) ||
			(navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
		!(window as any).MSStream;
	return isIOS && navigator.userAgent.match(/AppleWebKit/);
};

const hashCode = str => {
	let hash = 0,
		i,
		chr;
	if (str.length === 0) return hash;
	for (i = 0; i < str.length; i++) {
		chr = str.charCodeAt(i);
		hash = (hash << 5) - hash + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
};

const openFileByLink = (url: string) => {
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("target", "_blank");
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

const downloadFileByLink = (url: string) => {
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", "qr_code.png");
	link.setAttribute("target", "_blank");
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

const secondsToHumanTime = (secs: string) => {
	const sec_num = parseInt(secs, 10);
	const hours = Math.floor(sec_num / 3600);
	const minutes = Math.floor(sec_num / 60) % 60;
	const seconds = sec_num % 60;

	return `${hours ? `${hours}h` : ""}${minutes ? `${hours ? " " : ""}${minutes}m` : ""}${
		seconds ? `${minutes ? " " : ""}${seconds}s` : ""
	}`;
};

const mergeDateAndTime = (scheduleDate: Date | string, scheduleTime: Date | string) => {
	const scheduleDateData = new Date(scheduleDate);
	const scheduleTimeData = new Date(scheduleTime);

	return new Date(
		scheduleDateData.getFullYear(),
		scheduleDateData.getMonth(),
		scheduleDateData.getDate(),
		scheduleTimeData.getHours(),
		scheduleTimeData.getMinutes(),
		scheduleTimeData.getSeconds()
	);
};

const adjustColor = (color: string, amount: number) => {
	return (
		"#" +
		color
			.replace(/^#/, "")
			.replace(/../g, color => ("0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
	);
};

export const formatSizeUnits = (bytes?: number) => {
	let size = "";
	if (bytes && !isNaN(bytes)) {
		if (bytes >= 1073741824) {
			size = (bytes / 1073741824).toFixed(2) + " GB";
		} else if (bytes >= 1048576) {
			size = (bytes / 1048576).toFixed(2) + " MB";
		} else if (bytes >= 1024) {
			size = (bytes / 1024).toFixed(2) + " KB";
		} else if (bytes > 1) {
			size = bytes + " bytes";
		} else if (bytes === 1) {
			size = bytes + " byte";
		} else {
			size = "0 bytes";
		}
	}
	return size;
};

export const updateElSize = (baseEl?: HTMLElement, val = "", extraWidth = 0) => {
	if (baseEl) {
		const tempDiv = document.createElement("div");
		tempDiv.innerText = val;
		// tempDiv.style.cssText = window.getComputedStyle(inputRef.current, "").cssText;
		const styles = window.getComputedStyle(baseEl);
		if (styles.cssText !== "") {
			tempDiv.style.cssText = styles.cssText;
		} else {
			tempDiv.style.cssText = Object.values(styles).reduce(
				(css, propertyName) => `${css}${propertyName}:${styles.getPropertyValue(propertyName)};`
			);
		}
		tempDiv.style.opacity = "0";
		tempDiv.style.overflow = "hidden";
		tempDiv.style.maxHeight = "0";
		tempDiv.style.width = "fit-content";
		document.body.appendChild(tempDiv);
		(baseEl as HTMLInputElement).style.width = `${tempDiv.clientWidth + extraWidth}px`;
		document.body.removeChild(tempDiv);
	}
};

const uniqBy = <T>(array: T[], key: keyof T) => {
	const seen = new Set();
	return array.filter(item => {
		const el = item[key];
		return seen.has(el) ? false : seen.add(el);
	});
};

export const getRandomInt = (min: number, max: number) => {
	const minVal = Math.ceil(min);
	const maxVal = Math.floor(max);
	return Math.floor(Math.random() * (maxVal - minVal + 1)) + minVal;
};

export const getRandomText = () => {
	return (Math.random() + 1).toString(36).substring(7);
};

export const capitalize = (text: string) => {
	return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const getSymbolByNumber = (index: number) => {
	const alphabet = [
		"A",
		"B",
		"C",
		"D",
		"E",
		"F",
		"G",
		"H",
		"I",
		"J",
		"K",
		"L",
		"M",
		"N",
		"O",
		"P",
		"Q",
		"R",
		"S",
		"T",
		"U",
		"V",
		"W",
		"X",
		"Y",
		"Z"
	];
	if (index < 0) {
		return "-";
	}
	if (index >= alphabet.length) {
		const extraTimes = Math.floor(index / alphabet.length);
		return `${alphabet[extraTimes - 1] || "-"}${alphabet[index - alphabet.length * extraTimes]}`;
	}
	return alphabet[index];
};

export const getGenderTitle = (val: GenderEnum | GenderValEnum, t: TFunction) => {
	if (!t) {
		return "";
	}

	switch (val) {
		case GenderEnum.MALE:
		case GenderValEnum.MALE:
			return t("male");

		case GenderEnum.FEMALE:
		case GenderValEnum.FEMALE:
			return t("female");

		case GenderEnum.COED:
		case GenderValEnum.COED:
			return t("coed");

		default:
			return "";
	}
};

export const getAgeCategoryName = ({
	type,
	value,
	defaultVal
}: {
	type?: AgeGroupEnum;
	value?: number;
	defaultVal?: string;
}) => {
	if (type && value) {
		const letter = type === AgeGroupEnum?.UNDER ? "U" : type === AgeGroupEnum?.OVER ? "O" : "Open";
		return `${letter}${value || ""}`;
	}
	return defaultVal || "";
};

export const getAgeCategoryYear = (value?: DateTime | Date | string | null) => {
	if (value) {
		const luxonDate = getDateTime(value);
		return luxonDate.toFormat("yyyy");
	}
	return "";
};

export const getRoundedDistance = (distance: number) => {
	try {
		const rounded = distance.toFixed(1);
		return Number(rounded);
	} catch (e) {
		console.log("Can not get rounded distance for ", distance, e);
		return 0;
	}
};

export const getDatesDifference = (calcDate: Date | string, intervalType: keyof DurationLikeObject = "years") => {
	const date = DateTime.fromJSDate(new Date(calcDate));
	const currentDatetime = DateTime.now();
	const diff =
		currentDatetime > date
			? Interval.fromDateTimes(date, currentDatetime)
			: Interval.fromDateTimes(currentDatetime, date);
	return diff.length(intervalType);
};

export const getDifferenceBetweenDates = (
	startDate: Date | string,
	endDate: Date | string,
	intervalType: keyof DurationLikeObject = "years"
) => {
	const date1 = DateTime.fromJSDate(new Date(startDate));
	const date2 = DateTime.fromJSDate(new Date(endDate));
	const diff = date2 > date1 ? Interval.fromDateTimes(date1, date2) : Interval.fromDateTimes(date2, date1);
	return diff.length(intervalType);
};

export const getMonthOptions = (t: TFunction) =>
	t
		? [
				{ label: t("date:months.january"), value: 1 },
				{ label: t("date:months.february"), value: 2 },
				{ label: t("date:months.march"), value: 3 },
				{ label: t("date:months.april"), value: 4 },
				{ label: t("date:months.may"), value: 5 },
				{ label: t("date:months.june"), value: 6 },
				{ label: t("date:months.july"), value: 7 },
				{ label: t("date:months.august"), value: 8 },
				{ label: t("date:months.september"), value: 9 },
				{ label: t("date:months.october"), value: 10 },
				{ label: t("date:months.november"), value: 11 },
				{ label: t("date:months.december"), value: 12 }
			]
		: [];

export const getDaysOfMonthOptions = (month = 1) => {
	let daysCount = 31;
	const dayList: DropdownOptionModel[] = [];
	if ((month <= 7 && month % 2 === 0) || (month >= 7 && month % 2 !== 0)) {
		daysCount = 30;
	}
	if (month === 2) {
		daysCount = 28;
	}
	for (let i = 0; i < daysCount; i++) {
		dayList.push({ label: `${i + 1}`, value: i + 1 });
	}
	return dayList;
};

export const getYearOptions = (backToYears = 100) => {
	const currentYear = new Date().getFullYear();
	const yearList: DropdownOptionModel[] = [];
	for (let i = currentYear; i >= currentYear - backToYears; i--) {
		yearList.push({ label: `${i}`, value: i });
	}
	return yearList;
};

export const getCorrectGenderValList = (t: TFunction): DropdownOptionModel[] =>
	genderList
		.filter(x => x.valValue !== GenderValEnum.COED)
		.map(x => ({
			label: getGenderTitle(x.valValue, t),
			value: x.valValue
		}));

export const getFullGenderValList = (t: TFunction): DropdownOptionModel[] =>
	genderList.map(x => ({
		label: getGenderTitle(x.valValue, t),
		value: x.valValue
	}));

export const getFirstNameByName = (name?: string): string =>
	name && name.includes(" ") ? name!.split(" ")?.[0] || "" : "";
export const getLastNameByName = (name?: string): string =>
	name && name.includes(" ") ? name!.split(" ")?.[1] || "" : "";

const fileIsMediaModelFullObject = (src: MediaModel | MediaModelFullObject): src is MediaModelFullObject => {
	return src.hasOwnProperty("file");
};

export const generateFileUrl = (mediaFile: MediaModel | MediaModelFullObject) => {
	if (fileIsMediaModelFullObject(mediaFile))
		return `${appConfig.GLOBAL_CONSTANTS.AWS_CDN_URL}${mediaFile.file.location.key}?v=${mediaFile.file.location.ver}`;

	return `${appConfig.GLOBAL_CONSTANTS.AWS_CDN_URL}${mediaFile.path}?v=${mediaFile.version}`;
};

export const getProperWebsiteUrl = (website: string) => {
	let link = website;
	if (!link.includes("http://") && !link.includes("https://")) {
		link = `https://${link}`;
	}
	return link;
};

// export const generateCloudfrontFileUrl = (mediaFile: MediaModel) => {
// 	// return `${cloudfront_url}/${mediaFile.path}`;
// };

export const getAnchorOriginPos = (contentPosition: MenuListPositionOpts): PopoverOrigin => {
	switch (contentPosition) {
		case MenuListPositionOpts.TOP_LEFT:
			return { vertical: "top", horizontal: "left" };

		case MenuListPositionOpts.TOP_RIGHT:
			return { vertical: "top", horizontal: "right" };

		case MenuListPositionOpts.TOP_CENTER:
			return { vertical: "top", horizontal: "center" };

		case MenuListPositionOpts.BOTTOM_LEFT:
			return { vertical: "bottom", horizontal: "left" };

		case MenuListPositionOpts.BOTTOM_RIGHT:
			return { vertical: "bottom", horizontal: "right" };

		default:
			return {
				vertical: "bottom",
				horizontal: "center"
			};
	}
};

export const getTransformOriginPos = (popoverPosition): PopoverOrigin => {
	switch (popoverPosition) {
		case MenuListPositionOpts.TOP_LEFT:
			return { vertical: "top", horizontal: "left" };

		case MenuListPositionOpts.TOP_RIGHT:
			return { vertical: "top", horizontal: "right" };

		case MenuListPositionOpts.TOP_CENTER:
			return { vertical: "top", horizontal: "center" };

		case MenuListPositionOpts.BOTTOM_LEFT:
			return { vertical: "bottom", horizontal: "left" };

		case MenuListPositionOpts.BOTTOM_RIGHT:
			return { vertical: "bottom", horizontal: "right" };

		default:
			return {
				vertical: "bottom",
				horizontal: "center"
			};
	}
};

export const compareObjects = (o1: any, o2: any) => {
	for (const p in o1) {
		if (o1.hasOwnProperty(p)) {
			if (o1[p] !== o2[p]) {
				return false;
			}
		}
	}
	for (const p in o2) {
		if (o2.hasOwnProperty(p)) {
			if (o1[p] !== o2[p]) {
				return false;
			}
		}
	}
	return true;
};

export const groupBy = (xs: any[], key: string) =>
	xs.reduce((rv, x) => {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});

export {
	toBase64,
	dataUrlToFile,
	correctName,
	extractContentFromHTML,
	safelyParseJSON,
	filterFiles,
	convertToNumberFormat,
	downscaleImage,
	numberWithCommas,
	formatCount,
	isMobileSafari,
	hashCode,
	openFileByLink,
	downloadFileByLink,
	secondsToHumanTime,
	mergeDateAndTime,
	adjustColor,
	uniqBy
};
